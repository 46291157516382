import React from "react"
import { connect } from "react-redux"
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Segment,
  Label,
} from "semantic-ui-react"
import * as Yup from "yup"
import { Formik } from "formik"
import Alert from "../../alert/Alert"
import { signInUser } from "../index"

const Logo = require("../../../images/logo.jpg")
const styles = require("./SignIn.module.scss")

const SigninSchema = Yup.object().shape({
  email: Yup.string()
    .email("* Invalid Email")
    .required("* Email is Required"),
  password: Yup.string().required("* Password is Required"),
})

interface Props {
  signInUser: any
}

class SignIn extends React.Component<Props> {
  handleFormSubmit = (values: any) => {
    const { signInUser } = this.props
    const { email, password } = values
    signInUser({ email, password })
  }

  render() {
    return (
      <Grid
        textAlign="center"
        className={styles.container}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Alert />
          <Header as="h1" className={styles.header} textAlign="center">
            <Label color="black" style={{ backgroundColor: "#000000" }}>
              <Image src={Logo} />
            </Label>
            <br />
            Midwestern Pacific
          </Header>
          <Header as="h4" className={styles.header} textAlign="center">
            Please Sign In
          </Header>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={SigninSchema}
            onSubmit={values => {
              this.handleFormSubmit(values)
            }}
          >
            {({ handleSubmit, handleChange, values, errors, touched }) => (
              <Form size="large" onSubmit={handleSubmit}>
                <Segment stacked>
                  <Form.Input
                    required
                    fluid
                    icon="user"
                    onChange={handleChange}
                    iconPosition="left"
                    placeholder="E-mail address"
                    name="email"
                    value={values.email}
                  />
                  {errors.email && touched.email && (
                    <span className={styles.errorMessage}>{errors.email}</span>
                  )}
                  <Form.Input
                    fluid
                    required
                    icon="lock"
                    iconPosition="left"
                    placeholder="Password"
                    type="password"
                    value={values.password}
                    name="password"
                    onChange={handleChange}
                  />
                  {errors.password && touched.password && (
                    <span className={styles.errorMessage}>
                      {errors.password}
                    </span>
                  )}
                  <Button type="submit" color="teal" fluid size="large">
                    Login
                  </Button>
                </Segment>
              </Form>
            )}
          </Formik>
        </Grid.Column>
      </Grid>
    )
  }
}

export default connect(null, { signInUser })(SignIn)
