import React, { useEffect, useState } from "react"
import { Grid } from "semantic-ui-react"
import socketIOClient from "socket.io-client"
import axios from "axios"
import Button from "../components/Button"
import { load, page, track } from "../utils/analytics"
import { SERVER_URL } from "../modules/api"
import { IPINFO_URL } from "./constants"

const CoverImage = require("../images/front-cover.jpg")
const DarlaLogo = require("../images/darla-logo.png")

const styles = require("./Home.module.scss")

export default function Home() {
  const [locationMessage, setLocationMessage] = useState("")
  useEffect(() => {
    load()
    page()
    const socket = socketIOClient(SERVER_URL)
    // TODO: stronger typing
    axios.get(IPINFO_URL).then((data: any) => {
      // handle success
      socket.emit("clientVisit", {
        address: data.data.ip,
        city: data.data.city,
        region: data.data.region,
      })
      socket.on(`clientMessage${data.data.ip}`, (message: string) => {
        setLocationMessage(message)
      })
    })
  }, [])

  return (
    <div className={styles.container}>
      <header className={styles.hgroup}>
        <h1>Dennis Sheridan</h1>
        <h2>Midwestern Pacific</h2>
        <p>
          {locationMessage === "" && (
            <>
              <em>
                &ldquo;A sparse, loose but together, vocal and acoustic
                album&rdquo;
              </em>{" "}
              recorded in a shed in Southern California
            </>
          )}
          {locationMessage !== "" && <>{locationMessage}</>}
        </p>
      </header>
      <section className={styles.albumLinks}>
        <Grid columns={3} stackable>
          <Grid.Column className={styles.linkColumn}>
            <h3 className={styles.gold}>Start the Journey...</h3>
            <a
              href="https://spoti.fi/2xVWSwm"
              onClick={() => track("cover image clicked", {})}
              className={styles.coverImage}
            >
              <img src={CoverImage} alt="Midwestern Pacific Album Cover" />
            </a>
          </Grid.Column>
          <Grid.Column className={styles.linkColumn}>
            <h3 className={styles.gold}>Now Streaming</h3>
            <Button
              action={() => track("Spotify link clicked", {})}
              color="black"
              url="https://spoti.fi/2xVWSwm"
            >
              Spotify
            </Button>
            <Button
              action={() => track("Youtube link clicked", {})}
              color="black"
              url="https://www.youtube.com/playlist?list=OLAK5uy_lwhTDr6771P7T4fha0kAuqKPDW9ZvvYyY"
            >
              YouTube
            </Button>
            <Button
              action={() => track("Apple link clicked", {})}
              color="black"
              url="https://apple.co/2Y1Z2Fp"
            >
              Apple
            </Button>
            <Button
              action={() => track("Amazon link clicked", {})}
              color="black"
              url="https://amzn.to/2M3fN0v"
            >
              Amazon
            </Button>
            <Button
              action={() => track("Bandcamp link clicked", {})}
              color="black"
              url="https://dennissheridan.bandcamp.com"
            >
              Bandcamp
            </Button>
          </Grid.Column>
          <Grid.Column className={styles.linkColumn}>
            <h3 className={styles.gold}>Order the Vinyl</h3>
            <Button
              action={() => track("Darla link clicked", {})}
              color="black"
              url="https://darla.com/products/dennis-sheridan-midwestern-pacific"
            >
              Darla.com
            </Button>
            <h4 className={styles.gold}>Booking/Contact</h4>
            <a
              onClick={() => track("Email link clicked", {})}
              href="mailto:midwesternpacific@gmail.com"
              className={styles.instagram}
            >
              midwesternpacific@gmail.com
            </a>
            <figure className={styles.darlaLogo}>
              <a
                onClick={() => track("Darla logo clicked", {})}
                href="https://darla.com"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src={DarlaLogo} alt="Darla Records Logo" />
              </a>
            </figure>
          </Grid.Column>
        </Grid>
      </section>
      <section className={styles.tour}>
        <h1>Tour</h1>
        <p>No Upcoming Performances</p>
      </section>
      <section className={styles.about}>
        <h1>About</h1>
        <p>
          &ldquo;Dennis Sheridan is a singer/songwriter/guitar player from
          Louisville, KY who made Oceanside, CA his home almost a decade ago.
          Midwestern Pacific is a sparse, loose but together, vocal and acoustic
          album recorded by Dennis in his backyard office/music
          room/outbuilding. He had the album mastered by Kramer (Bongwater,
          Shimmy Disc). I hear Neil Young, Bob Dylan, Randy Newman, Warren
          Zevon, Steve Kilbey.
        </p>
        <p>
          Dennis is a veteran Darla family artist. His first record for Darla
          was Follow The Train - A Breath of Sigh (2006), which Popmatters
          called &quot;Chock-full of delicate melodies and sweet instrumentation
          inspired by the likes of the Church, R.E.M., and the Cure.&quot; We
          followed up with a digital reissue of Follow The Train - Mercury,
          which was originally released in 2010 via Jim James of My Morning
          Jacket&apos;s label Removador Remedies and Solutions. We also released
          The Toothache EP and A Clever Few by his quartet EndCastle
          (2018).&rdquo;
        </p>
        <p>
          <em>~James Agren, Darla Records</em>
        </p>
        <p>
          [ Artwork by{" "}
          <a
            onClick={() => track("Bigtime link clicked", {})}
            href="https://www.instagram.com/mikebigtime/"
          >
            Mike Bigtime
          </a>{" "}
          ]
        </p>
      </section>
      <footer className={styles.footer}>
        <a
          onClick={() => track("Privacy link clicked", {})}
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.termsfeed.com/privacy-policy/93144ac56b610baea310a2235230a0b7"
        >
          Privacy Policy
        </a>
        <a
          onClick={() => track("Terms link clicked", {})}
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.termsfeed.com/terms-conditions/9dda83f00551640aa5c19c0915107304"
        >
          Terms &amp; Conditions
        </a>
      </footer>
    </div>
  )
}
