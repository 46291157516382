import React from "react"
import { Container } from "semantic-ui-react"
import Alert from "../modules/alert/Alert"
import SiteHeader from "./Header"

interface Props {
  children: React.ReactNode
}

export default function Layout(props: Props) {
  return (
    <div>
      <SiteHeader />
      <Container text style={{ marginTop: "7em" }}>
        <Alert />
        {props.children}
      </Container>
    </div>
  )
}
