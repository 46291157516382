import { put, takeLatest } from "redux-saga/effects"
import { setAlert, clearAlerts } from "../alert"
import {
  SIGNIN_REQUEST_SUCCESS,
  SIGN_OUT_USER,
  SIGNIN_REQUEST_FAILURE,
} from "./index"

function* handleSignOut() {
  yield put(clearAlerts())
  yield put(
    setAlert({
      alertType: "positive",
      heading: "",
      content: "You've been successfully signed out",
    })
  )
}

function* handleSignIn() {
  yield put(clearAlerts())
  yield put(
    setAlert({
      alertType: "positive",
      heading: "",
      content: "Welcome, you've been signed in",
    })
  )
}

function* handleSignInFailure() {
  yield put(clearAlerts())
  yield put(
    setAlert({
      alertType: "negative",
      heading: "",
      content: "Login credentials failed",
    })
  )
}

export default function* sessionSaga() {
  yield takeLatest(SIGN_OUT_USER, handleSignOut)
  yield takeLatest(SIGNIN_REQUEST_SUCCESS, handleSignIn)
  yield takeLatest(SIGNIN_REQUEST_FAILURE, handleSignInFailure)
}
