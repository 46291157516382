import { combineReducers, createStore, applyMiddleware, compose } from "redux"

import thunk from "redux-thunk"
import createSagaMiddleware from "redux-saga"
import auth from "../modules/auth"
import alert from "../modules/alert"
import AuthSaga from "../modules/auth/sagas"

const persistedState = localStorage.getItem("midwesternPacific")
  ? JSON.parse(localStorage.getItem("midwesternPacific"))
  : {}

const persistedKeys = ["auth"]
const rootReducer = combineReducers({
  alert,
  auth,
})

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  rootReducer,
  { ...persistedState },
  compose(
    applyMiddleware(thunk),
    applyMiddleware(sagaMiddleware),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  )
)

// verifyCredentials(store)

sagaMiddleware.run(AuthSaga)

store.subscribe(() => {
  const state = store.getState()
  const persistedState = {}
  persistedKeys.map(key => {
    if (state[key]) {
      // eslint-disable-next-line no-return-assign
      return (persistedState[key] = state[key])
    }
    return null
  })
  localStorage.setItem("midwesternPacific", JSON.stringify(persistedState))
})

export default store
