import React from "react"
import { useHistory } from "react-router"
import { useSelector } from "react-redux"
import { getAuthState } from "../modules/auth"
import { LOGIN_PATH, DASHBOARD_PATH } from "./constants"

export const requireSignIn = Component => ({ ...props }) => {
  const signedIn = useSelector(getAuthState).signedIn
  const history = useHistory()
  React.useEffect(() => {
    if (!signedIn) history.replace(LOGIN_PATH)
  }, [signedIn, history])
  return signedIn ? <Component {...props} /> : null
}

export const redirectIfSignedIn = Component => ({ ...props }) => {
  const signedIn = useSelector(getAuthState).signedIn
  const history = useHistory()
  React.useEffect(() => {
    if (signedIn) history.replace(DASHBOARD_PATH)
  }, [signedIn, history])
  return !signedIn ? <Component {...props} /> : null
}
