// TODO: implement header checks for token verification
export const getAuthHeaders = () => ({
  "access-token": localStorage.getItem("access-token"),
  client: localStorage.getItem("client"),
  "token-type": localStorage.getItem("token-type"),
  uid: localStorage.getItem("uid"),
})

export const SERVER_URLS = {
  production: "",
  development: window.location && `http://${window.location.hostname}:5000`,
}

export const SERVER_URL =
  process.env.NODE_ENV !== "production"
    ? SERVER_URLS.development
    : SERVER_URLS.production

export const API_URL = `${SERVER_URL}/api`
