declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS
  }
}

export const load = () => {
  window.analytics.load("tBkffuna0nbDwFJXFnUtVbBTv52Cc43J")
}
export const page = () => {
  window.analytics.page()
}
export const track = (name: string, properties: any) => {
  window.analytics.track(name, properties)
}
