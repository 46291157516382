import React from "react"
import { useDispatch } from "react-redux"
import { Container, Button, Image, Menu, Icon } from "semantic-ui-react"
import { signOutUser } from "../modules/auth"

const Logo = require("../images/logo-sm.jpg")

export default function Header() {
  const dispatch = useDispatch()
  return (
    <React.Fragment>
      <Menu fixed="top" inverted>
        <Container>
          <Menu.Item as="a" header href="/">
            <Image size="mini" src={Logo} style={{ marginRight: "1.5em" }} />
            Midwestern Pacific Admin
          </Menu.Item>
          <Menu.Item position="right">
            <Button onClick={() => dispatch(signOutUser())} inverted>
              Sign Out <Icon style={{ margin: 0 }} name="sign out" />
            </Button>
          </Menu.Item>
        </Container>
      </Menu>
    </React.Fragment>
  )
}
