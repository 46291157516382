export interface AlertMessage {
  alertType?: string
  heading?: string
  content?: string
}

export interface AlertState {
  alertMessages: AlertMessage[]
}

// ------------------------------------
// Selectors
// ------------------------------------
export const getMessages = (state: any) => state.alert.alertMessages

// ------------------------------------
// Constants
// ------------------------------------
const MODULE_NAME = "alert"
export const SET_ALERT = `${MODULE_NAME}/SET_ALERT`
export const CLEAR_ALERTS = `${MODULE_NAME}/CLEAR_ALERTS`
export const DELETE_ALERT = `${MODULE_NAME}/DELETE_ALERT`
export const UPDATE_ALERTS = `${MODULE_NAME}/UPDATE_ALERTS`

// ------------------------------------
// Utils
// ------------------------------------
const filterMessages = (messages: AlertMessage[], alert: AlertMessage) => {
  return messages.filter((message: AlertMessage) => {
    return (
      message.content !== alert.content && message.alertType !== alert.alertType
    )
  })
}

// ------------------------------------
// Actions
// ------------------------------------
export const setAlert = (alert: AlertMessage) => ({
  type: SET_ALERT,
  payload: alert,
})

export const clearAlerts = () => ({
  type: CLEAR_ALERTS,
})

export const deleteAlert = (alert: AlertState) => ({
  type: DELETE_ALERT,
  payload: alert,
})

export const actions = {
  setAlert,
  deleteAlert,
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_ALERT]: (state: AlertState, action: any) => {
    return {
      alertMessages: [
        { ...action.payload },
        ...filterMessages([...state.alertMessages], { ...action.payload }),
      ],
    }
  },
  [CLEAR_ALERTS]: () => {
    return { alertMessages: [] }
  },
  [DELETE_ALERT]: (state: AlertState, action: any) => {
    return {
      alertMessages: [
        ...filterMessages([...state.alertMessages], { ...action.payload }),
      ],
    }
  },
  [UPDATE_ALERTS]: (state: AlertState, action: any) => {
    return { alertMessages: [...action.payload] }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  alertMessages: [],
}

export default function reducer(state = initialState, action: any) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
