import React from "react"
import { hydrate, render } from "react-dom"
import { Provider } from "react-redux"
import RouteIndex from "./routes"
import * as serviceWorker from "./serviceWorker"
// import { verifyCredentials } from "./modules/auth/redux-token-auth-config"
import store from "./store"

// verifyCredentials(store)

require("./index.scss")

const root: HTMLElement | null = document.getElementById("root")

let shouldHydrate: boolean = false
if (root instanceof HTMLElement) {
  shouldHydrate = root.hasChildNodes()
}

if (shouldHydrate) {
  hydrate(
    <Provider store={store}>
      <RouteIndex />
    </Provider>,
    root
  )
} else {
  render(
    <Provider store={store}>
      <RouteIndex />
    </Provider>,
    root
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
