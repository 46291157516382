import React from "react"
import classNames from "classnames"

const styles = require("./Button.module.scss")

interface Props {
  buttonType: "button" | "link"
  url?: string
  action?: Function
  children: React.ReactNode
  color: string
  style: React.CSSProperties
}

export default class Button extends React.Component<Props> {
  static defaultProps = {
    buttonType: "link",
    style: {},
  }

  render() {
    const { buttonType, children, style, color } = this.props
    const action = this.props.action ? this.props.action : () => {}
    const url = this.props.url ? this.props.url : ""
    const btnClass = classNames(styles.button, styles[color])

    return (
      <React.Fragment>
        {buttonType === "button" && this.props.action && (
          <button onClick={() => action()} style={style} className={btnClass}>
            {children}
          </button>
        )}
        {buttonType === "link" && this.props.url && (
          <a
            onClick={() => action()}
            href={url}
            style={style}
            className={btnClass}
          >
            {children}
          </a>
        )}
      </React.Fragment>
    )
  }
}
