import React, { useEffect, useReducer, useState } from "react"
import { Grid } from "semantic-ui-react"
import socketIOClient from "socket.io-client"
import { SERVER_URL } from "../modules/api"

interface Client {
  id: number
  address: string
  city: string
  region: string
}

function reducer(
  state: { clients: Client[] },
  action: {
    type: string
    payload: { clients?: Client[]; client?: Client; id?: number }
  }
): { clients: Client[] } {
  switch (action.type) {
    case "set":
      return { clients: action.payload.clients || state.clients }
    case "addClient":
      return {
        clients: action.payload.client
          ? [action.payload.client, ...state.clients]
          : state.clients,
      }
    case "removeClient":
      return {
        clients: action.payload.id
          ? state.clients.filter(client => client.id !== action.payload.id)
          : state.clients,
      }
    default:
      throw new Error()
  }
}

interface ClientProps {
  client: Client
}

function Client(props: ClientProps) {
  const [message, setMessage] = useState("")
  const { id, address, city, region } = props.client

  const sendMessage = () => {
    const socket = socketIOClient(SERVER_URL)
    socket.emit(`clientMessage`, {
      message,
      address,
    })
  }

  return (
    <div>
      <h3>
        {id} | {address} | {city} | {region}
      </h3>
      <textarea value={message} onChange={e => setMessage(e.target.value)} />
      <br />
      <button type="button" onClick={() => sendMessage()}>
        Send Message
      </button>
      <br />
      <br />
      <hr />
      <br />
    </div>
  )
}

export default function Dashboard() {
  const initialState = {
    clients: [],
  }
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const socket = socketIOClient(SERVER_URL)
    socket.on("connectedClients", (connectedClients: Client[]) => {
      dispatch({ type: "set", payload: { clients: connectedClients } })
    })
    socket.on("newClient", (client: Client) => {
      dispatch({ type: "addClient", payload: { client } })
    })
    socket.on("clientLeft", (id: number) => {
      dispatch({ type: "removeClient", payload: { id } })
    })
    socket.emit("adminVisit")
  }, [])

  return (
    <Grid style={{ backgroundColor: "black" }}>
      <div>
        {state.clients.map((client, i) => {
          return <Client client={client} key={i} />
        })}
      </div>
    </Grid>
  )
}
