import axios from "axios"
import { API_URL } from "../api"

const SIGN_IN_API_URL = `${API_URL}/sign_in`

export interface User {
  email: string
  token: string
}

export interface AuthState {
  user: User
  signedIn: boolean
}

// ------------------------------------
// Selectors
// ------------------------------------
export const getAuthState = (state: any) => state.auth
export const getUser = (state: any) => getAuthState(state).user

// ------------------------------------
// Constants
// ------------------------------------
const MODULE_NAME = "auth"
export const SIGNIN_REQUEST = `${MODULE_NAME}/SIGNIN_REQUEST`
export const SIGNIN_REQUEST_SUCCESS = `${MODULE_NAME}/SIGNIN_REQUEST_SUCCESS`
export const SIGNIN_REQUEST_FAILURE = `${MODULE_NAME}/SIGNIN_REQUEST_FAILURE`
export const SIGN_OUT_USER = `${MODULE_NAME}/SIGN_OUT_USER`
export const SAVE_USER = `${MODULE_NAME}/SAVE_USER`

// ------------------------------------
// Actions
// ------------------------------------
export const signInUser = (data: User) => {
  return (dispatch: Function) => {
    dispatch({ type: SIGNIN_REQUEST })
    return axios
      .post(SIGN_IN_API_URL, { ...data })
      .then(response => {
        dispatch({ type: SIGNIN_REQUEST_SUCCESS })
        dispatch({
          type: SAVE_USER,
          payload: response.data,
        })
      })
      .catch(() => {
        dispatch({ type: SIGNIN_REQUEST_FAILURE })
        // throw(error)
      })
  }
}

export const signOutUser = () => ({
  type: SIGN_OUT_USER,
})

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SAVE_USER]: (state: AuthState, action: any) => {
    return { user: action.payload, signedIn: true }
  },
  [SIGN_OUT_USER]: () => {
    return { user: {}, signedIn: false }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  user: {
    email: "",
    token: "",
  },
  signedIn: false,
}

export default function reducer(state = initialState, action: any) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
