import React from "react"
import { Message } from "semantic-ui-react"
import { connect } from "react-redux"
import { getMessages, AlertMessage, deleteAlert } from "./index"

interface DispatchProps {
  deleteAlert: Function
}

type AlertProps = AlertMessage & DispatchProps

const Alert = (props: AlertProps) => {
  const { alertType, heading, content, deleteAlert } = props
  const msgProps = alertType ? { [alertType]: true } : {}

  React.useEffect(() => {
    setTimeout(() => deleteAlert({ alertType, heading, content }), 6000)
  })

  return (
    <Message {...msgProps}>
      <Message.Header>{heading}</Message.Header>
      <p>{content}</p>
    </Message>
  )
}

const AlertComponent = (props: any) => {
  const { deleteAlert } = props

  if (props.alertMessages.length > 0) {
    return props.alertMessages.map((message: AlertMessage, i: number) => {
      return <Alert {...message} deleteAlert={deleteAlert} key={i} />
    })
  }
  return null
}

const mapStateToProps = (state: any) => ({
  alertMessages: getMessages(state),
})

export default connect(mapStateToProps, { deleteAlert })(AlertComponent)
