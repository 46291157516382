import React, { Component } from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { requireSignIn, redirectIfSignedIn } from "./onRouteEnter"
import { LOGIN_PATH, DASHBOARD_PATH } from "./constants"
import Layout from "./Layout"
import Home from "./Home"
import Dashboard from "./Dashboard"
import SignIn from "../modules/auth/components/SignIn"

export default class RouteIndex extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path={LOGIN_PATH} component={redirectIfSignedIn(SignIn)} />
          <Layout>
            <Route path={DASHBOARD_PATH} component={requireSignIn(Dashboard)} />
          </Layout>
        </Switch>
      </Router>
    )
  }
}
